/* sets initial margin padding to 0 */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
}